import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {Redirect} from 'react-router-dom';

const NoUser = props => {

    const [loggedIn, setLoggedIn] = useGlobal('loggedIn');

    if (!loggedIn) {
        return <Redirect to="login"/>
    }
    return (
        <div>
                <h1>Geen gebruiker voor dit account</h1>
                <p>Contacteer een admin om je account te activeren in de app</p>
        </div>
)
};

export default NoUser;