import React from 'react';
import { GoogleLogin, GoogleLogout  } from 'react-google-login';
//import { useGlobal, setGlobal } from 'reactn';
import { getGlobal, resetGlobal, setGlobal, useGlobal, useEffect } from 'reactn';
import {useLazyQuery, gql} from '@apollo/client';

import {refreshTokenSetup} from './util/refreshToken';
import { Redirect } from 'react-router-dom';




function Login( {history} ){
  const [ loggedIn, setLoggedIn ] = useGlobal('loggedIn');
  const [ accessToken, setAccessToken] = useGlobal('accessToken');
  const [me, setMe] = useGlobal('me');
  const [tokenId, setTokenId] = useGlobal('tokenId');

  const meQuery = gql`
    query {
      me {
        id
        google_id
        role {
          code
        }
      }
    }
  `;

  // const [getMe] = useLazyQuery(meQuery, {onCompleted: res => {
  //   console.log('me res: ', res);
  // }, onError: e => {
  //   console.error('me error:', e);
  // }});

  const [getMe, {data, error, loading}] = useLazyQuery(meQuery);
  // console.log('global', global)
  // console.log(loggedIn)
  if (error) {
    console.log('Error in Login.js', error);
  }

  if (!!data && !loading) {
    if (!me) {
        if (!!data.me) {
        setMe({...data.me})
          history.push("/");
        }
        else {
          history.push('/unauthorized')
        }
    }
      }

  if (!loggedIn){
  return (
    <div className="LoginPageMain">
    <div className="GoogleAuth">
      	<img src="https://i.ibb.co/C2yRDH8/Background.png" className="logo" height="30px"/>

        
        <h1 className="google">Inloggen</h1>
        <p className="google">Je APK Google-account gebruiken</p>

        <GoogleLogin 
        // responseType="code"
          className ="googleButton"
          clientId="944762034876-hs398hn6do3e01uh4ih1upvk24mf9iq0.apps.googleusercontent.com"
          buttonText="Login"
          // approvalPrompt="force"
          // prompt='consent'
          // offline={true}
          onSuccess={async (responseGoogle) => {
            // console.log('basic profile', responseGoogle);
              localStorage.setItem("Token", responseGoogle.tokenId);
              localStorage.setItem("Email", responseGoogle.getBasicProfile().getEmail());
              localStorage.setItem("Name", responseGoogle.getBasicProfile().getName());
              setAccessToken(responseGoogle.accessToken);
              setTokenId(responseGoogle.tokenId)
              setLoggedIn(true);
              refreshTokenSetup(responseGoogle);
              // console.log(responseGoogle);
              getMe();
              // <Redirect to={"/"} />
              // console.log(history)
          }}
          onFailure={(responseGoogle) => console.log(responseGoogle)}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
          // scope='https://www.googleapis.com/auth/admin.directory.group'
          scope='https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/gmail.compose'
        />
    </div>
    </div>
  );
  }else{
    return(
      <div className="LoginPageMain">

    <div className="GoogleAuth">
      	<img src="https://i.ibb.co/C2yRDH8/Background.png" className="logo" height="30px"/>

        
        <h1 className="google">Uitloggen</h1>
        <p className="google">Je bent al ingelogd</p>

        <GoogleLogout
          className ="googleButton"
          clientId="944762034876-hs398hn6do3e01uh4ih1upvk24mf9iq0.apps.googleusercontent.com"
          buttonText="Logout"
          onLogoutSuccess={(responseGoogle) => {
            setLoggedIn(false);
            localStorage.setItem("Token", '');
            localStorage.setItem("Email",'');
            setMe(null);
          }
        }
        >
        </GoogleLogout>
    </div>
    </div>

    )
  }
}

export default Login;


