import React from 'react';
import CreateWerkbon from './CreateWerkbon';
import WerkbonTable from './WerkbonTable';
import { getGlobal, resetGlobal, setGlobal, useGlobal } from 'reactn';
import { Redirect } from "react-router-dom";

import NotLoggedIn from './NotLoggedIn';

const MainWrapper = () => {
      
        const [ loggedIn, setLoggedIn ] = useGlobal('loggedIn');
        const [me, setMe] = useGlobal('me');
        if (!loggedIn){
                return (
                        <Redirect to={"/login"} />
                )
        } else if (loggedIn && !!me){
        return(
                <div className="main">
                        <CreateWerkbon/>
                        <WerkbonTable/>
                </div>   
        )
        }

}

export default MainWrapper;