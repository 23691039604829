import React, { useEffect, useState, useContext } from 'react';
import Thumbnail from './Thumbnail';
import {Link} from 'react-router-dom';
import NoWerkbon from './NoWerkbon';
import SearchWerkbon from './WerkbonSearch';
import { WerkbonContext } from './werkbonContext';
import { MyWerkbonContext } from './MyWerkbonContext'
import {useGlobal} from 'reactn';

const LatestWerkbonnen = () => {
        // const [def, setDef] = useState(MyWerkbonContext)
        
        // const recenteWerkbonnen = useContext(def)

        const [recentWorkorders, setRecentWorkorders] = useGlobal('recentWorkorders');
        const [myWorkorders, setMyWorkorders] = useGlobal('myWorkorders');
        const [me, setMe] = useGlobal('me');

        const [check, setCheck] = useState(me.role.code === 'ADMIN' ? false: true)

        useEffect(() => {
                console.log('in table');
                
        }, []);

        useEffect(() => {
                // console.log('myWorkorders in table: ', myWorkorders)
                // console.log('recentWorkorders in table: ', recentWorkorders)
        }, [myWorkorders, recentWorkorders])


        const toggleMyWorkorders = () => {
                // console.log("change");
                
                // if (def == MyWerkbonContext){
                if (check){

                        setCheck(false);
                        // setDef(WerkbonContext);
                }else{
                        setCheck(true);
                        // setDef(MyWerkbonContext);
                }

        }


        return(
                
                <div className="WerkbonTable">
                        <div className='flex-row'>
                        <h1>Werkbonnen</h1>
                        {/* <p>{JSON.stringify(data.workorders)}</p> */}
                        
                        <SearchWerkbon/>
                        </div>
                        <div className="flex-row no-spacing werkbonCheckbox">
                                <p>Alle werkbonnen</p>
                                <label htmlFor="toggle" className="toggle-container">
                                        <input type="checkbox" id="toggle" className="real-checkbox" onChange={toggleMyWorkorders} checked={check}/>
                                        <div className="toggle-button"></div>
                                </label>
                                <p>Mijn werkbonnen</p>
                        </div>
                        <div className="werkbonFirstTableRow"  > 
                                        <span className="coll">No.</span>
                                        <span className="coll">Eigenaar</span>
                                        <span className="coll">Klant</span>
                                        <span className="coll">Datum</span>
                                        {/* <span className="coll">Email</span> */}
                                        <span className="coll">Status</span>
                                </div>
                        <div className="Werkbonnen">
                               
                                        {
                                                        !check? (   
                                                            !!recentWorkorders? (
                                                                recentWorkorders.map(werkbon =>(
                                                                <Link className="link" to={`/details/${werkbon.id}`} key={werkbon.id}>
                                                                        <Thumbnail woNumber = { werkbon.workorder_number } name = { werkbon.owner} company = {werkbon.customer} date = {werkbon.date} email = {werkbon.contact} status = {werkbon.status} classname='coll unlink' />
                                                                </Link>
                                                ))):(<NoWerkbon/>)                                                
                                                ):(
                                                        !!myWorkorders? (
                                                        myWorkorders.map(werkbon =>(
                                                                <Link className="link" to={`/details/${werkbon.id}`} key={werkbon.id}>
                                                                        <Thumbnail woNumber = { werkbon.workorder_number } name = { werkbon.owner} company = {werkbon.customer} date = {werkbon.date} email = {werkbon.contact} status = {werkbon.status} classname='coll unlink' />
                                                                </Link> ))):(<NoWerkbon/>)   
                                        )
                                }       
                        </div>
                </div>
        )
        }

        export default LatestWerkbonnen;