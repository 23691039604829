import React, {useState, useEffect} from 'react';
//import Thumbnail from './Thumbnail';
import Details from "./Details";
import MainWrapper from './MainWrapper';
import LoginPage from './LoginPage';
import EditWerkbon from './EditWerkbon';
import NavBar from './NavBar.jsx'
import WerkbonnenPageMain from './WerkbonTableMobilePage'
import {withRouter ,BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Footer from './Footer';
import {WerkbonProvider} from './werkbonContext';
import {MyWerkbonProvider} from './MyWerkbonContext';
import UserManagement from './userManagement';
import NoUser from './NoUser';
import { getGlobal, resetGlobal, setGlobal, useGlobal } from 'reactn';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { ApolloProvider } from '@apollo/client';

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import Login from './Login';
// Set the value.
setGlobal({ loggedIn: false });

// Get the value.
// alert(getGlobal().loggedIn); // 1

 function App (){
  const [ loggedIn, setLoggedIn ] = useGlobal('LoggedIn');
  const [accessToken, setAccessToken] = useGlobal('accessToken');
  const [tokenId, setTokenId] = useGlobal('tokenId');

  const [client, setClient] = useState(null);
  useEffect(() => {
    console.log('updating client');
    let httpLink = createHttpLink({
      uri: 'https://api-33k7vpwdbq-ew.a.run.app/',
      // uri: 'http://localhost:8080',
      // credentials: 'same-origin',
      headers: {
        'authorization': tokenId,
        'accessToken': accessToken
      }
    });

    let client = new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink
    });

    setClient(client);
  }, [accessToken, tokenId]);

 if (!client) {
    return null;
  }
   return (  
     
    <ApolloProvider client={client}>
      <ToastContainer />
      <MyWerkbonProvider>
      <WerkbonProvider>
      

        <Router>
        <div>
          <NavBar />
            
          <Switch>
            <Route path="/" exact component={MainWrapper}/>
            <Route path="/details/:id" component={withRouter(Details)}/>
            <Route path="/login"  component={Login}/>
            <Route path="/werkbonnen" component={WerkbonnenPageMain}/>
            <Route path="/bewerk/:id" component={withRouter(EditWerkbon)}/>
            <Route path="/gebruikersbeheer" component={withRouter(UserManagement)}/>
            <Route path="/unauthorized" component={withRouter(NoUser)}/>

          </Switch>
        </div>      

            
      
        <Footer/>
        </Router>
        </WerkbonProvider>     
        </MyWerkbonProvider>                
           
      </ApolloProvider>

  );
}


export default App