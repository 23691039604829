import React from 'react';
import { Link} from 'react-router-dom';



const Footer = () => {
	return (
		<footer className="footer">

			<div className="filler"></div>

		</footer>
	)
}

export default Footer