import React, {useState, useContext} from 'react';
import { WerkbonContext } from './werkbonContext';
import {useGlobal} from 'reactn';

// const api_key = '13e0fd0bac0e7d48d68f47b1d59bdb10';


 
const SearchWerkbon = ({callback}) => {
    const [pages, setPages] = useState(0);
   // const [werkbon, setWerkbon] = useContext(WerkbonContext);
    // const [werkbon, setWerkbon] = useState('');
    const [searchVars, setSearchVars] = useGlobal('searchVars');

    async function search(e){
        var query = e.target.value
        setSearchVars({...searchVars, search: query})
      } 
      

    return (
        <div>
            <p>Search:</p>
             <input name="Zoekveld" defaultValue={searchVars? (searchVars.search || ''): ''} onChange={search}/>
        </div>
    )
}

export default SearchWerkbon;
