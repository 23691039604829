import React from 'react';
import WerkbonTableMobile from './WerkbonTableMobileOnly'
import { getGlobal, resetGlobal, setGlobal, useGlobal } from 'reactn';
import NotLoggedIn from './NotLoggedIn';
import { Redirect } from 'react-router-dom';
const WerkbonnenPageMain =  props  => {
    const [ loggedIn, setLoggedIn ] = useGlobal('loggedIn');
    
        if (loggedIn){
        return (
            <div className="WerkbonnenPageMain">
                <WerkbonTableMobile />
            </div>
        )
        }else{
            return (
                <Redirect to='/login'/>
            )
        }
}

export default WerkbonnenPageMain;