import React, {useState} from 'react';
import "./App.css";



function WerkbonTableRow({name, company, date, email, classname, woNumber, status}){
    // console.log("date", date)
    // var d = new Date(date);
    // var dd = String(d.getDate()).padStart(2, '0');
    // var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = d.getFullYear();
    const parsedDate = new Date(Number(date)).toLocaleDateString('nl-BE')
    
    //date = yyyy + '-' + mm + '-' + dd;
    
    return (
        <div className="werkbonTableRow"  > 
                                        {(<span className= {classname} style={{minWidth: '128px'}}>{woNumber || ''}</span>)}
                                        {( <span className= {classname}>{name || ''}</span> ) }
                                        {( <span className={classname}>{company || ''}</span> )}
                                        {( <span className={classname}>{parsedDate || ''}</span> )}
                                        {/* {( <span className={classname}>{email || ''}</span> )} */}
                                        {( <span className={classname}>{status || ''}</span> )}
                                </div>
    );
}

export default WerkbonTableRow