import React, { useState, useContext, useEffect } from 'react';
import { WerkbonContext } from './werkbonContext';
import NoWerkbon from './NoWerkbon';
import { Link } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import { useQuery, gql, useMutation } from '@apollo/client';
import axios from 'axios';
import Loading from './util/Loading';

import { useDispatch } from 'reactn';

import { toast } from 'react-toastify';

const toastOption = {
  // onOpen: props => console.log(props.foo),
  // onClose: props => console.log(props.foo),
  autoClose: true,
  // closeButton: FontAwesomeCloseButton,
  // type: toast.TYPE.INFO,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  pauseOnHover: true,
  // transition: MyCustomTransition,
  // progress: 0.2
  // and so on ...
};


const mutation = gql`
        mutation CreateWorkorder($workorder: WorkorderInput!) {
          createWorkorder(workorder: $workorder) {
            id
            workorder_number
          }
        }
      `;

const updateMutation = gql`
        mutation UpdateWorkorder($workorder: WorkorderInput!) {
          updateWorkorder(workorder: $workorder) {
            id
            status
          }
        }
`;

const getDateFormat = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return year + '-' + month + '-' + dt;
}

const werkbonModel = {
  id: '',
  workorder_number: '',
  date: getDateFormat(new Date()),
  customer: '',
  owner: '',
  description: '',
  contact: '',
  signature: '',
  updated_by: '',
  updated_at: '',
  created_by: '',
  created_at: '',
  name: '',
  status: 'NEW',
  contact_name: '',
}

const CreateWerkbon = () => {

  const refetchRecentWorkorders = useDispatch('refetchRecentWorkorders');
  const refetchMyWorkorders = useDispatch('refetchMyWorkorders');

  const [selectedWorkorder, setSelectedWorkorder] = useState({ ...werkbonModel });

  const [sigPad, setSigpad] = useState({});
  const [trimmedDataURL, setTrimmedDataUrl] = useState(sigPad);
  const [loading, setLoading] = useState(false)


  const [CreateWorkorder, { data: mutationData }] = useMutation(mutation);
  const [UpdateWorkorder] = useMutation(updateMutation);

  useEffect(() => {
    console.log('in create');
}, []);


  const addName = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, name: val };
    })
  }
  const addCustomer = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, customer: val };
    })
  }
  const addContact = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, contact: val };
    })
  }
  const addDate = (e) => {
    const val = e.target.value;
    console.log(val);
    setSelectedWorkorder(old => {
      return { ...old, date: val };
    })
  }
  const addDescription = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, description: val };
    })
  }

  const addContactName = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, contact_name: val };
    })
  }
  const addSignature = (e) => {
    const val = sigPad.getTrimmedCanvas().toDataURL('image/png');
    setSelectedWorkorder(old => {
      return { ...old, signature: val };
    })

  }

  const createWerkbon = async (e) => {
    // console.log('trimmedDataURL', trimmedDataURL)
    e.preventDefault();

    setLoading(true);
    try {
      const createRes = await CreateWorkorder({
        variables: {
          workorder: {
            //id: '', 
            contact: selectedWorkorder.contact,
            owner: localStorage.Email,
            customer: selectedWorkorder.customer,
            date: new Date(selectedWorkorder.date).toISOString(),
            description: selectedWorkorder.description,
            name: selectedWorkorder.name,
            signature: selectedWorkorder.signature,
            status: selectedWorkorder.status,
            contact_name: selectedWorkorder.contact_name
          }
        }
      });

      // console.log(new Date(selectedWorkorder.date).toISOString());

      if (selectedWorkorder.signature) {
        const fileParams = {
          customer: selectedWorkorder.customer,
          name: selectedWorkorder.name,
          description: selectedWorkorder.description,
          technician: localStorage.getItem('Name'),
          signature: selectedWorkorder.signature,
          date: new Date(selectedWorkorder.date).toISOString(),
          workorder_number: createRes.data.createWorkorder.workorder_number,
          contact_name: selectedWorkorder.contact_name,
          idToken: localStorage.getItem('Token'),
        }
        const copyRes = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/generatePdf', fileParams);

        let info = 'Hallo, \n'
        info += '\n'
        info += 'In bijlage vindt u een kopie van het getekende document. \n'
        info += '\n'
        info += 'Met vriendelijke groet, \n'
        info += 'Het ATN-team';

        let email = selectedWorkorder.contact
        let params = {
          info: info,
          email: email,
          technician: localStorage.getItem('Name'),
          sender: localStorage.Email,
          file: copyRes.data.content,
          fileName: `Werkbon ${createRes.data.createWorkorder.workorder_number}`,
          idToken: localStorage.getItem('Token'),
        }
        const res = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/cf_sendmail', params)
        console.log('createRes: ', createRes);
        const updateRes = await UpdateWorkorder({
          variables: {
            workorder: {
              id: createRes.data.createWorkorder.id,
              status: 'SENT'
            }
          }
        });
        const driveParams = {
          idToken: localStorage.getItem('Token'),
          file: copyRes.data.content,
          name: `Werkbon ${createRes.data.createWorkorder.workorder_number}.pdf`,
        }
        const driveRes = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/saveToDrive', driveParams)
        console.log('updateRes: ', updateRes);
        setSelectedWorkorder(werkbonModel);
        toast.success("Werkbon verzonden!", toastOption);
      }
    }
    catch (e) {
      console.error(e);
      toast.error('Er ging iets mis...', toastOption);
    }
    finally {
      setLoading(false);
      refetchMyWorkorders();
      refetchRecentWorkorders();
    }

  }
  if (loading) {
    return (
      <div className="createWerkbonWrapper">
        <Loading />
      </div>
    );
  }
  return (
    <div className="createWerkbonWrapper">
      <h1>Bewerk werkbon</h1>
      <form className="newWerkbon" onSubmit={(e) => { createWerkbon(e) }} >
        <div className="flex-row">
          <div className="flexColumn">
            <label>Titel</label>
            <input className="normalInput" type="text" name="name" onChange={addName} value={selectedWorkorder.name} required />
          </div>
          <div className="flexColumn">
            <label>Datum</label>
            <input className="normalInput" type="date" name="datum" onChange={addDate} value={selectedWorkorder.date} required />
          </div>
          <div className="flexColumn">
            <label>Naam klant</label>
            <input className="normalInput" type="text" name="customer" onChange={addCustomer} value={selectedWorkorder.customer} required />
          </div>
        </div>
        <div className="flexColumn">
          <label>Omschrijving</label>
          <textarea className="longtextInput" name="description" onChange={addDescription} value={selectedWorkorder.description} />
        </div>
        <br/><hr/><br/>
        <div className="flex-row">
        <div className="flexColumn">
            <label>Naam contactpersoon</label>
            <input className="normalInput" type="text" name="contact_name" onChange={addContactName} value={selectedWorkorder.contact_name} required />
          </div>
          <div className="flexColumn">
            <label>Klant e-mail</label>
            <input className="normalInput" type="email" name="contact" onChange={addContact} value={selectedWorkorder.contact} required />
          </div>
        </div>
        <div className="flexColumn">
          <label>Handtekening klant</label>
          <SignatureCanvas penColor='red'
            onEnd={addSignature}
            canvasProps={{ className: 'longtextInput sigCanvas', }}
            ref={(ref) => { setSigpad(ref) }}
            clearOnResize={false}
          />
          <button className="ClearButton"
          onClick={(e) => {
            e.preventDefault();
            sigPad.clear();
            setSelectedWorkorder(old => {
              return { ...old, signature: '' };
            })
          }}
          >Wissen</button>
        </div>
        <button className="SaveButton" >Opslaan</button>
      </form>
    </div>
  )
}
export default CreateWerkbon