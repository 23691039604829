import React from 'react';
import { Link} from 'react-router-dom';
import styled from 'styled-components';

import Burger from './Burger.jsx';

const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  
`

const NavBar = () => {
	return (
		<nav className="navbar">
			
				<Link to= "/" className ="link unlink">
					<img src="https://i.ibb.co/C2yRDH8/Background.png" className="logo" height="65px"/>
				</Link>
				<Burger />
		</nav>
	)
}

export default NavBar