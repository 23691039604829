import React from 'react';
import styled from 'styled-components';
import { Link} from 'react-router-dom';

import { getGlobal, resetGlobal, setGlobal, useGlobal } from 'reactn';

const Ul = styled.ul`
  z-index: 10;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  .nav-p {
    padding: 18px 10px;
  }
  @media (max-width: 1230px) {
    flex-flow: column nowrap;
    background-color: #323232;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    .nav-p {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  const [ loggedIn, setLoggedIn ] = useGlobal('loggedIn');
  const [me, setMe] = useGlobal('me');



if (!loggedIn || !me){
  console.log('me in nav: ', me);
	return (
		// <ul className="menuItems" open={open}>
		// 				 
		// 			 </ul>

		<Ul open={open} className="menuItems">
            {/* <Link to="/"  className =" link unlink" >
      <li className="nav-p unlink">Werkbon maken</li>
      </Link>

      <Link to="/werkbonnen"  className =" link unlink werkbonNavMobile" >
		<li className="nav-p unlink">Overzicht</li>
		</Link> */}

		<Link to= "/login"  className =" link unlink " >
		<li className="nav-p unlink "  >Login</li>
		</Link>

    </Ul>
     )
  } else {
    console.log('me in nav: ', me)
    return (

      <Ul open={open} className="menuItems">
        <Link to="/"  className =" link unlink" >
      <li className="nav-p unlink">Werkbon maken</li>
      </Link>
  
      
      <Link to="/werkbonnen"  className =" link unlink werkbonNavMobile" >
		<li className="nav-p unlink">Overzicht</li>
		</Link>
    {me.role.code === 'ADMIN' && (
      <Link to="/gebruikersbeheer" className=" link unlink">
      <li className="nav-p unlink "  >Beheer gebruikers</li>
      </Link>
    )}
      <Link to= "/login"  className =" link unlink " >
      <li className="nav-p unlink ">Log uit</li>
      </Link>
  
      </Ul>
       )
  }
}

export default RightNav