import React, { createContext, useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useGlobal, addReducer } from 'reactn';



export const WerkbonContext = createContext();
// this provider purely provides the data with a get call from the gql api. Does not support mutations. ONLY get, no post
export const WerkbonProvider = props => {

  const [workOrders, setWorkorders] = useGlobal('recentWorkorders');
  const [searchVars, setSearchVars] = useGlobal('searchVars');
  const [me, setMe] = useGlobal('me');

  const handleData = res => {
    // console.log('res in provider: ', res);
    setWorkorders([...res.workorders]);
  }

  const remoteRefetch=async()=>{
    const res = await refetch(searchVars);
      if (!!res.data) {
        handleData(res.data);
      }
  }

  useEffect(() => {
    if (!!me) {
      if (me.role.code === 'ADMIN') {
        // setSearchVars(old => {
        //   return {...old, includeAll: true}
        // })
        setSearchVars({...searchVars, includeAll: true})
      }
      else {
        // setSearchVars(old => {
        //   return {...old, includeAll: false}
        // })
        setSearchVars({...searchVars, includeAll: false})
      }
    }
  }, [me]);

  const query = gql`
    query Workorders($includeAll: Boolean, $search: String){
            workorders(includeAll: $includeAll, search: $search) {
            id
            workorder_number
            date
            customer
            owner
            description
            contact
            status
            signature
            updated_by
            updated_at
            created_by
            created_at
            name
            }
      }
      `;


      const { loading, error, data, refetch } = useQuery(query, {fetchPolicy: 'network-only', onCompleted: res => {
        return handleData(res);
      },
      variables: searchVars
    });

    // useEffect(() => {
    //   if (!data) {
    //     return;
    //   }
    //   // console.log('searchVars changed! ', searchVars);
    //   refetch({...searchVars}).then(res => {
    //     console.log('searchVars updated: ', res);
    //     if (!!res.data) {
    //       handleData(res.data);
    //     }
    //   })
    // }, [searchVars])

      addReducer('refetchRecentWorkorders', async () => {
        const res = await refetch(searchVars);
        if (!!res.data) {
          handleData(res.data);
        }
      })
      //const [werkbon, setWerkbon] = useState(data)
      if (loading || !workOrders) return (
        <WerkbonContext.Provider value={false}>
                {props.children}
          </WerkbonContext.Provider>
      )
      if (error) return (
        <WerkbonContext.Provider value={!error}>
        {props.children}
      </WerkbonContext.Provider>
      )

      return (
          <WerkbonContext.Provider value={{workOrders, remoteRefetch}}>
                {props.children}
          </WerkbonContext.Provider>
      )
}
