import React, { useEffect, useState, useContext } from 'react';
import {useGlobal} from 'reactn';
import Thumbnail from './Thumbnail';
import {Link} from 'react-router-dom';
import NoWerkbon from './NoWerkbon';
import SearchWerkbon from './WerkbonSearch';
import { WerkbonContext } from './werkbonContext';
 import { MyWerkbonContext } from './MyWerkbonContext'

const LatestWerkbonnen = () => {
        const [def, setDef] = useState(MyWerkbonContext)
        
        // const recentWorkorders = useContext(def)
        const [recentWorkorders, setRecentWorkorders] = useGlobal('recentWorkorders');
        const [myWorkorders, setMyWorkorders] = useGlobal('myWorkorders');

        const [check, setCheck] = useState(true)


        const toggleMyWorkorders = () => {
                console.log("change");
                
                if (def == MyWerkbonContext){
                        setCheck(false);
                        setDef(WerkbonContext);
                }else{
                        setCheck(true);
                        setDef(MyWerkbonContext);
                }

        }


        return(
                
                <div className="WerkbonTableMobile">
                        <div className="no-spacing mobileWerkbonCheckbox">
                        <SearchWerkbon/>

                        <span >Mijn werkbonnen</span>
                                <p className="mobileDisappearer">Alle werkbonnen</p>
                                <label htmlFor="toggle" className="toggle-container">
                                        <input type="checkbox" id="toggle" className="real-checkbox" onChange={toggleMyWorkorders}  checked={check}/>
                                        <div className="toggle-button"></div>
                                </label>
                               
                        </div>
                        <div className='flex-row'>
                        <h1> Recente werkbonnen</h1>
                        {/* <p>{JSON.stringify(data.workorders)}</p> */}
                        
                        </div>
                        
                        <div className="werkbonFirstTableRow"  > 
                                        <span className="mobileColl">Eigenaar</span>
                                        <span className="mobileColl">Klant</span>
                        </div>
                        <div className="Werkbonnen">
                               
                        {
                                                (
                                                        !check? (  
                                                                !!recentWorkorders? (      
                                                        recentWorkorders.map(werkbon =>(
                                                                !werkbon.signature? (
                                                                <Link className="link" to={`/details/${werkbon.id}`}>
                                                                        <Thumbnail name = { werkbon.owner} company = {werkbon.customer}  classname='mobileColl unlink' />
                                                                </Link>
                                                                ) : (console.log("verzonden"))
                                                ))
                                                ):(
                                                <NoWerkbon/>
                                                )):(
                                                        !!myWorkorders?(
                                                        myWorkorders.map(werkbon =>(
                                                                !werkbon.signature? (
                                                                <Link className="link" to={`/details/${werkbon.id}`}>
                                                                        <Thumbnail name = { werkbon.owner} company = {werkbon.customer}  classname='mobileColl unlink' />
                                                                </Link>    
                                                                ) : (console.log("verzonden"))
                                                )) ):
                                                (<NoWerkbon/>)
                                        )
                                )}      
                        </div>
                </div>
        )
        }

        export default LatestWerkbonnen;