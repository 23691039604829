import React, { useState, useContext, useEffect } from 'react';
import { WerkbonContext } from './werkbonContext';
import NoWerkbon from './NoWerkbon';
import { Link, Redirect } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import { useQuery, gql, useMutation } from '@apollo/client';
import Loading from './util/Loading';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useGlobal } from 'reactn';

const toastOption = {
  // onOpen: props => console.log(props.foo),
  // onClose: props => console.log(props.foo),
  autoClose: true,
  // closeButton: FontAwesomeCloseButton,
  // type: toast.TYPE.INFO,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  pauseOnHover: true,
  // transition: MyCustomTransition,
  // progress: 0.2
  // and so on ...
};

const mutation = gql`
        mutation EditWorkorder($workorder: WorkorderInput!) {
          updateWorkorder(workorder: $workorder) {
            id
            workorder_number
          }
        }
      `;

const query = gql`
        query GetWorkorderById($id: ID!){
          workorder(id: $id) {
            id
            workorder_number
            date
            customer
            owner
            description
            contact
            signature
            updated_by
            updated_at
            created_by
            created_at
            name
            contact_name
          }
        }
`;

const werkbonModel = {
  id: '',
  workorder_number: '',
  // date: '',
  customer: '',
  owner: '',
  description: '',
  contact: '',
  signature: '',
  updated_by: '',
  updated_at: '',
  created_by: '',
  created_at: '',
  name: '',
  contact_name: '',
}

const EditWerkbon = React.memo(({ match, history }) => {

  console.log(JSON.stringify(match));
  const [selectedWorkorder, setSelectedWorkorder] = useState({ ...werkbonModel });

  const [sigPad, setSigpad] = useState({});
  const [trimmedDataURL, setTrimmedDataUrl] = useState(sigPad);
  const [id, setId] = useState(parseInt(match.params.id))
  const [isLoading, setIsLoading] = useState(false);
  const [originalWorkOrder, setOriginalWorkOrder] = useState({});


  const [updateWorkorder, { data: mutationData }] = useMutation(mutation);

  const refetchRecentWorkorders = useDispatch('refetchRecentWorkorders');
  const refetchMyWorkorders = useDispatch('refetchMyWorkorders');

  const [ loggedIn, setLoggedIn ] = useGlobal('loggedIn');

  const getDateFormat = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
  
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + '-' + month + '-' + dt;
  }


  const { loading, error, data } = useQuery(query, {
    variables: { id: id }, onCompleted: selWo => {
      console.log(selWo);
      console.log(`${Number(selWo.workorder.date) + 20}`);
      setSelectedWorkorder({ ...selWo.workorder, date: `${Number(selWo.workorder.date) + 20}` });
      setOriginalWorkOrder({...selWo.workorder, date: `${Number(selWo.workorder.date) + 20}`})
    }
  });

  // const [werkbon, setWerkbon] = useState('');

  if (!loggedIn) {
    console.log('not logged in');
    return <Redirect to='/login'/>
  }

  if (loading || error) return (
    <NoWerkbon />
  )


  const addName = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, name: val };
    })
  }
  const addCustomer = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, customer: val };
    })
  }
  const addContact = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, contact: val };
    })
  }

  const addContactName = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, contact_name: val };
    })
  }

  const addDate = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      console.log(new Date(val).getTime());
      return { ...old, date: new Date(val).getTime() };
    })
  }
  const addDescription = (e) => {
    const val = e.target.value;
    setSelectedWorkorder(old => {
      return { ...old, description: val };
    })
  }
  const addSignature = (e) => {
    const val = sigPad.getTrimmedCanvas().toDataURL('image/png');
    setSelectedWorkorder(old => {
      return { ...old, signature: val };
    })

  }

  const createWerkbon = async (e) => {
    console.log('trimmedDataURL', trimmedDataURL)
    e.preventDefault();
    console.log(data)
    //setWerkbon(old => [...old, {_id: match.params.id, name: name, company: company, date: date, email: email, remarks: remarks, signature: trimmedDataURL }]); 
    // console.log(werkbon)
    try {
      setIsLoading(true);
      console.log(selectedWorkorder.date);
      const createRes = await updateWorkorder({
        variables: {
          workorder: {
            id: id,
            contact: selectedWorkorder.contact,
            owner: localStorage.Email,
            customer: selectedWorkorder.customer,
            date: new Date(Number(selectedWorkorder.date)).toISOString(),
            description: selectedWorkorder.description,
            name: selectedWorkorder.name,
            signature: selectedWorkorder.signature,
            contact_name: selectedWorkorder.contact_name,
            status: 'NEW'
          }
        }
        // window.location = "/details/" + selectedWorkorder.id;
        //  <Redirect to={"/details/" + selectedWorkorder.id}/>
      });
      console.log(createRes);
      if (selectedWorkorder.signature) {
        const fileParams = {
          customer: selectedWorkorder.customer,
          name: selectedWorkorder.name,
          description: selectedWorkorder.description,
          signature: selectedWorkorder.signature,
          technician: localStorage.getItem('Name'),
          date: new Date(Number(selectedWorkorder.date)).toISOString(),
          workorder_number: selectedWorkorder.workorder_number,
          contact_name: selectedWorkorder.contact_name,
          idToken: localStorage.getItem('Token'),
        }
        const copyRes = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/generatePdf', fileParams);

        let info = 'Hallo, \n'
        info += '\n'
        info += 'In bijlage vindt u een kopie van het getekende document. \n'
        info += '\n'
        info += 'Met vriendelijke groet, \n'
        info += 'Het ATN-team';

        let email = selectedWorkorder.contact
        console.log('sender', localStorage)

        let params = {
          info: info,
          email: email,
          file: copyRes.data.content,
          technician: localStorage.getItem('Name'),
          sender: localStorage.Email,
          fileName: `Werkbon ${selectedWorkorder.workorder_number}`,
          idToken: localStorage.getItem('Token'),
        }
        const res = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/cf_sendmail', params)
        console.log('createRes: ', createRes);
        const updateRes = await updateWorkorder({
          variables: {
            workorder: {
              id: selectedWorkorder.id,
              status: 'SENT'
            }
          }
        });
        const driveParams = {
          idToken: localStorage.getItem('Token'),
          file: copyRes.data.content,
          name: `Werkbon ${selectedWorkorder.workorder_number}.pdf`,
        }
        const driveRes = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/saveToDrive', driveParams) 
        // console.log('updateRes: ', updateRes);
        history.push('/details/' + selectedWorkorder.id);
        toast.success("Werkbon verzonden!", toastOption);
      }

    }
    catch (e) {
      console.error(e);
      toast.error('Er ging iets mis...', toastOption);
    }
    finally {
      setIsLoading(false);
      refetchMyWorkorders();
      refetchRecentWorkorders();
    }
    //match.history.push("/")
  }


  if (isLoading) {
    return <Loading />
  }
  return (
    <div className="editWerkbonWrapper">
      <h1>Bewerk werkbon</h1>
      <form className="newWerkbon" onSubmit={(e) => { createWerkbon(e) }} >
        <div className="flex-row">
          <div className="flexColumn">
            <label>Titel</label>
            <input className="normalInput" type="text" name="name" onChange={addName} value={selectedWorkorder.name} required />
          </div>
          <div className="flexColumn">
            <label>Datum</label>
            {selectedWorkorder.date && (<input className="normalInput" type="date" name="datum" onChange={addDate} 
            defaultValue={getDateFormat(new Date(Number(selectedWorkorder.date)))} required />)}
          </div>
        </div>
        <div className="flexColumn">
          <label>Omschrijving</label>
          <textarea className="longtextInput" name="description" onChange={addDescription} value={selectedWorkorder.description} />
        </div>
        <br/><hr/><br/>
        <div className="flex-row">
          <div className="flexColumn">
            <label>Klant</label>
            <input className="normalInput" type="text" name="customer" onChange={addCustomer} value={selectedWorkorder.customer} required />
          </div>
          <div className="flexColumn">
            <label>Naam contactpersoon</label>
            <input className="normalInput" type="text" name="contact" onChange={addContactName} value={selectedWorkorder.contact_name || ''} required />
          </div>
          <div className="flexColumn">
            <label>Klant e-mail</label>
            <input className="normalInput" type="email" name="contact_name" onChange={addContact} value={selectedWorkorder.contact} required />
          </div>
        </div>
        {!originalWorkOrder.signature && (
          <div className="flexColumn">
            <label>Handtekening klant</label>
            <SignatureCanvas penColor='red'
              onEnd={addSignature}
              canvasProps={{ className: 'longtextInput sigCanvas', }}
              ref={(ref) => { setSigpad(ref) }}
              clearOnResize={false}
            />
            <button className="ClearButton"
          onClick={(e) => {
            e.preventDefault();
            sigPad.clear();
            setSelectedWorkorder(old => {
              return { ...old, signature: '' };
            })
          }}
          >Wissen</button>
          </div>
        )}
        <button className="SaveButton" >Opslaan</button>
      </form>
    </div>
  )
})




export default EditWerkbon