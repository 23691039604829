import './userManagement.css';

import React, { useState, useEffect } from 'react';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { useGlobal, getGlobal } from 'reactn';
import { getTestMessageUrl } from 'nodemailer';
import { Redirect } from 'react-router-dom';

import Loading from './util/Loading'

const userQuery = gql`
    query {
        googleUsers {
        email
        name
    }
    users {
        id
        google_id
        role {
          id
          code
        }
    }
    roles {
        id
        name
        code
    }
}
`;

const createUserQuery = gql`
    mutation CreateUser($user: UserInput!) {
        createUser(user: $user) {
            id
        }
    }
`;

const deleteUserQuery = gql`
    mutation DeleteUser($user: UserInput!) {
        deleteUser(user: $user) {
            id
        }
    }
`;

const updateUserQuery = gql`
    mutation UpdateUser($user: UserInput!) {
        updateUser(user: $user) {
            id
        }
    }
`;

const UserManagement = props => {
    const global = getGlobal();
    const [getUsers, { error, loading, data, refetch }] = useLazyQuery(userQuery);
    const [linkedUsers, setLinkedUsers] = useState([]);

    const [createUser] = useMutation(createUserQuery);
    const [deleteUser] = useMutation(deleteUserQuery);
    const [updateUser] = useMutation(updateUserQuery);

    const mergeUsers = (users, googleAccounts) => {
        let res = googleAccounts.map((ga, i) => {
            const u = users.find(thisUser => thisUser.google_id === ga.email);
            return { ...ga, linkedUser: u ? { ...u } : null };
        });
        // Remove me form list so the user can't delete themself
        res.splice(res.indexOf((res.find(el => el.email == global.me.google_id))), 1)
        return res;
    };

    const handleUserToggled = async (googleUser) => {
        if (!googleUser.linkedUser) {
            try {
                const userRes = await createUser({ variables: { user: { google_id: googleUser.email, role_fk: 2 } } });
            }
            catch (e) {
                console.error(e);
            }
            finally {
                setLinkedUsers([]);
                const refetchRes = await refetch();
            }
        }
        else {
            try {
                const userRes = await deleteUser({ variables: { user: { id: googleUser.linkedUser.id } } });
            }
            catch (e) {
                console.error(e);
            }
            finally {
                setLinkedUsers([]);
                const refetchRes = await refetch();
            }
        }
    }

    const changeRole = async (googleUser, newRole) => {
        try {
            const userRes = await updateUser({variables: {user: {id: googleUser.linkedUser.id, role_fk: Number(newRole)}}});
        }
        catch(e) {
            console.error(e);
        }
        finally {
            setLinkedUsers([]);
            const refetchRes = await refetch();
        }
    }

    useEffect(() => {
        if (!!global.me) {
            getUsers();
        }
    }, []);

    useEffect(() => {
        if (!!data && !loading) {
            console.log(data)
            setLinkedUsers(mergeUsers(data.users, data.googleUsers));
        }
    }, [data])

    if (error) {
        console.error(error);
    }

    if (!global.me) {
        return (<Redirect to='/login'></Redirect>)
    }

    if (!!loading && !data) {
        return <Loading/>
    }

    return (
        <>
            <h1 className="title">Gebruikersbeheer</h1>
            {(!!linkedUsers && linkedUsers.length > 0) && (
                <div id="user-mgmt-table-wrapper">
                <table className='user-mgmt-table'>
                    <thead>
                        <tr>
                            <th>Naam</th>
                            <th>E-mail</th>
                            <th>App toegang</th>
                            <th>Rol</th>
                        </tr>
                    </thead>
                    <tbody>
                        {linkedUsers.map((lu, index) => {
                            return (<tr key={'lu-' + index}>
                                <td>{lu.name}</td>
                                <td>{lu.email}</td>
                                <td><input type='checkbox' checked={!!lu.linkedUser} onChange={e => { handleUserToggled(lu) }} /></td>
                                {lu.linkedUser && (
                                    <td>
                                        <select defaultValue={lu.linkedUser.role.id} onChange={(e) => {
                                            changeRole(lu, e.target.value);
                                        }}>
                                            {data.roles.map((el, index) => {
                                                return (
                                                    <option value={el.id} key={el.id}>{el.name}</option>
                                                )
                                            })}
                                        </select>
                                    </td>
                                )}
                            </tr>)
                        })}
                    </tbody>
                </table>
                </div>
            )}
            {(!!linkedUsers && linkedUsers.length === 0) && (<Loading/>)}
        </>
    );
};

export default UserManagement;