import React from 'react';

const NoWerkbon = () => {
        return (
                <div className="no-movies">
                        <p>
                            Geen werkbonnen gevonden. Probeer het later opnieuw
                        </p>
                </div>
        )
}

export default NoWerkbon;