import React, { useState, useContext } from 'react';
import { useGlobal } from 'reactn';
import { WerkbonContext } from './werkbonContext';
import NoWerkbon from './NoWerkbon';
import { Link, Redirect } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import Loading from './util/Loading';
import { toast } from 'react-toastify';
import * as b64toBlob from 'b64-to-blob';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const toastOption = {
        // onOpen: props => console.log(props.foo),
        // onClose: props => console.log(props.foo),
        autoClose: true,
        // closeButton: FontAwesomeCloseButton,
        // type: toast.TYPE.INFO,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: true,
        // transition: MyCustomTransition,
        // progress: 0.2
        // and so on ...
};

const downloadFileFromBlob = (blob, fileName) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
};

const getWoQuery = gql`
        query WorkOrder($id: ID!) {
                workorder(id: $id) {
                        id
                        workorder_number
                        date
                        customer
                        owner
                        description
                        contact
                        contact_name
                        signature
                        updated_by
                        updated_at
                        created_by
                        created_at
                        name
                        status

                }
        }
`;

const updateMutation = gql`
        mutation UpdateWorkorder($workorder: WorkorderInput!) {
          updateWorkorder(workorder: $workorder) {
            id
            status
          }
        }
`;

const deleteMutation = gql`
        mutation DeleteWorkorder($id: ID!) {
                deleteWorkorder(id: $id) {
                        id
                        status
                }
        }
`;

const Details = ({ match, history }) => {

        const [currentWorkorder, setCurrentWorkorder] = useState(null);
        // console.log('match: ', Number(match.params.id));
        const { error, loading: queryLoading, data } = useQuery(getWoQuery, {
                variables: { id: Number(match.params.id), }, fetchPolicy: 'network-only', onCompleted: res => {
                        // console.log(res);
                        setCurrentWorkorder(res.workorder)
                }
        });

        const [loading, setLoading] = useState(false)
        const [accessToken, setAccessToken] = useGlobal('accessToken');
        const [me, setMe] = useGlobal('me');

        const [UpdateWorkorder] = useMutation(updateMutation);
        const [deleteWorkorder] = useMutation(deleteMutation);
        const { remoteRefetch } = useContext(WerkbonContext);
        const [loggedIn, setLoggedIn] = useGlobal('loggedIn');
        // console.log(loggedIn)

        console.log('me: ', me);
        if (!loggedIn) {
                return (
                        <Redirect to={"/login"} />
                )
        }
        else if (!me) {
                return <Redirect to={'/unauthorized'}/>
        }

        const processWorkorder = async wo => {
                setLoading(true);
                try {
                        const mutRes = await UpdateWorkorder({ variables: { workorder: { id: wo.id, status: 'PROCESSED' } } });
                        console.log(mutRes);
                        toast.success(`Werkbon ${wo.workorder_number} is verwerkt!`, toastOption);
                        history.push('/')
                }
                catch (e) {
                        console.error(e);
                        toast.error('Er ging iets mis...', toastOption);
                }
                finally {
                        setLoading(false);
                }
        };

        const unSign = async wo => {
                confirmAlert({
                        title: 'Bent u zeker?',
                        message: 'Handtekening verwijderen en opnieuw vrijgeven?',
                        buttons: [
                          {
                            label: 'Vrijgeven',
                            onClick: async() => {
                                setLoading(true);
                                try{
                                        await UpdateWorkorder({ variables: {workorder: {id: wo.id, status: 'NEW', signature: ''}} })
                                        toast.success(`Werkbon ${wo.workorder_number} is vrijgegeven!`, toastOption);
                                        await remoteRefetch();
                                        history.push('/');
                                }catch(err){
                                        toast.error('Er ging iets mis tijdens het vrijgeven...', toastOption);
                                }finally{
                                        setLoading(false);
                                }
                            }
                          },
                          {
                            label: 'Annuleren'
                          }
                        ]
                      });
        }

        const sendEmail = async (wo) => {
                setLoading(true);
                try {
                        // console.log(new Date(selectedWorkorder.date).toISOString());

                        if (wo.signature) {
                                const fileParams = {
                                        idToken: localStorage.getItem('Token'),
                                        customer: wo.customer,
                                        name: wo.name,
                                        description: wo.description,
                                        signature: wo.signature,
                                        date: wo.date,
                                        technician: localStorage.getItem('Name'),
                                        workorder_number: wo.workorder_number,
                                        contact_name: wo.contact_name
                                }
                                const copyRes = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/generatePdf', fileParams);

                                let info = 'Hallo, \n'
                                info += '\n'
                                info += 'In bijlage vindt u een kopie van het getekende document. \n'
                                info += '\n'
                                info += 'Met vriendelijke groet, \n'
                                info += 'Het ATN-team';

                                let email = wo.contact

                                let params = {
                                        info: info,
                                        email: email,
                                        file: copyRes.data.content,
                                        sender: me.google_id,
                                        fileName: `Werkbon ${wo.workorder_number}`,
                                        accessToken: accessToken,
                                        idToken: localStorage.getItem('Token'),
                                }
                                console.log('params: ', params);
                                const res = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/cf_sendmail', params)
                                const updateRes = await UpdateWorkorder({
                                        variables: {
                                                workorder: {
                                                        id: wo.id,
                                                        status: 'SENT'
                                                }
                                        }
                                });
                                const driveParams = {
                                        idToken: localStorage.getItem('Token'),
                                        file: copyRes.data.content,
                                        name: `Werkbon ${wo.workorder_number}.pdf`,
                                      }
                                      const driveRes = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/saveToDrive', driveParams) 
                                // console.log('updateRes: ', updateRes);
                                toast.success(`Werkbon verzonden naar ${wo.contact}!`, toastOption);
                        }
                }
                catch (e) {
                        console.error(e);
                        toast.error('Er ging iets mis...', toastOption);
                }
                finally {
                        setLoading(false);
                }

        }

        const getFile = async (wo) => {
                setLoading(true);
                try {
                        const fileParams = {
                                customer: wo.customer,
                                name: wo.name,
                                description: wo.description,
                                signature: wo.signature,
                                technician: localStorage.getItem('Name'),
                                date: wo.date,
                                workorder_number: wo.workorder_number,
                                contact_name: wo.contact_name,
                                idToken: localStorage.getItem('Token'),
                        }
                        const copyRes = await axios.post('https://europe-west1-workorders-285607.cloudfunctions.net/generatePdf', fileParams);
                        // console.log(copyRes);
                        // const blob = new Blob([`${copyRes.data.content}`], { type: "application/pdf" });
                        const blob = b64toBlob(copyRes.data.content, 'application/pdf');
                        downloadFileFromBlob(blob, wo.workorder_number + '.pdf');
                }
                catch (e) {
                        console.error(e);
                        toast.error('Er ging iets mis...', toastOption);
                }
                finally {
                        setLoading(false);
                }
        }

        const handleDelete=async()=>{
                confirmAlert({
                        title: 'Bent u zeker?',
                        message: 'Deze werkbon zal definitief verwijderd worden.',
                        buttons: [
                          {
                            label: 'Verwijderen',
                            onClick: async() => {
                                const { id, workorder_number } = currentWorkorder;
                                setLoading(true);
                                try{
                                        await deleteWorkorder({ variables: { id: id } });
                                        toast.success(`Werkbon ${workorder_number} is verwijderd!`, toastOption);
                                        await remoteRefetch();
                                        history.push('/');
                                }catch(err){
                                        toast.error('Er ging iets mis tijdens het verwijderen...', toastOption);
                                }finally{
                                        setLoading(false);
                                }
                            }
                          },
                          {
                            label: 'Annuleren'
                          }
                        ]
                      });
        }

        // const [id, setId] = useState(match.params.id)
        // console.log(id)

        // const recenteWerkbonnen = useContext(WerkbonContext)

        // console.log('recenteWerkbon: ', recenteWerkbonnen)


        // if (recenteWerkbonnen) {
        // for (var i = 0; i < recenteWerkbonnen.workorders.length; i++) {
        // console.log('recenteWerkbon: ', recenteWerkbonnen.workorders[i])
        if (!!currentWorkorder && !loading) {
                return (

                        <div className="details">
                                <div className="aside ">

                                        <div className='asideBody'>

                                                <div className={(currentWorkorder.signature && me.role.code !== "ADMIN") ? 'noEdit editIcon' : 'edit editIcon'}
                                                        data-popup={
                                                                currentWorkorder.signature ?
                                                                        'Deze werkbon is al getekend en kan niet aangepast worden'
                                                                        :
                                                                        'Bewerkt deze werkbon'
                                                        }>
                                                        <Link className="link" to={`/bewerk/${currentWorkorder.id}`} >
                                                                {/* hidden={recenteWerkbonnen.workorders[i].signature} on this image is so that they cannot edit workorders if signature is in database */}
                                                                <img src="https://i.ibb.co/RB4fQVg/hiclipart-com.png" width="30px" />
                                                        </Link>
                                                </div>

                                                <div className="flex-row">
                                                        <div className="infodiv">
                                                                <h4>Titel</h4>
                                                                <p>{currentWorkorder.name}</p>
                                                        </div>
                                                        <div className="infodiv">
                                                                <h4>Datum</h4>
                                                                <p>{new Date(Number(currentWorkorder.date)).toLocaleDateString('nl-BE')}</p>
                                                        </div>
                                                        <div className="infodiv">
                                                                <h4>Status</h4>
                                                                <p>{currentWorkorder.status}</p>
                                                        </div>
                                                </div>
                                                <div className="flex-row">
                                                        <div className="infodiv">
                                                                <h4>Naam klant</h4>
                                                                <p>{currentWorkorder.customer}</p>
                                                        </div>
                                                        <div className="infodiv">
                                                                <h4>Naam contactpersoon</h4>
                                                                <p>{currentWorkorder.contact_name}</p>
                                                        </div>
                                                        <div className="infodiv">
                                                                <h4>Klant e-mail</h4>
                                                                <p>{currentWorkorder.contact}</p>
                                                        </div>
                                                </div>
                                                <div className="infodiv flexColumn">
                                                        <h4>Omschrijving</h4>
                                                        {!currentWorkorder.description ? <p>Geen beschrijving ingegeven</p> : (
                                                                <p>{currentWorkorder.description}</p>
                                                        )}
                                                </div>
                                                <div className="infodiv flexColumn">
                                                        <h4>Handtekening</h4>
                                                        {!currentWorkorder.signature ? <p>Nog niet getekend</p> :
                                                                <img className="signatureImg" src={currentWorkorder.signature} />
                                                        }
                                                </div>

                                                <div className="adminPanel">
                                                        <div className=" adminIcon" data-popup={"Download de pdf"} onClick={() => getFile(currentWorkorder)}><img src="https://i.ibb.co/gmmJjhF/download-button-free-web-icons-icon-png-download-1200-630.png" width="30px" /></div>
                                                        <div className=" adminIcon" data-popup={"Bon verzenden"} onClick={() => sendEmail(currentWorkorder)}><img src="https://i.ibb.co/zPPTSmV/sendMail.png" width="30px" /></div>
                                                        {me.role.code === 'ADMIN' && (
                                                                <div className=" adminIcon" data-popup={"Deze bon verwijderen"} onClick={()=>handleDelete()}><img src="https://i.ibb.co/fSZR9vY/Trashcan.png" width="30px" /></div>
                                                        )}
                                                        {(me.role.code === 'ADMIN' && currentWorkorder.status !== 'NEW') && (
                                                        <div className=" adminIcon" data-popup={"Vrijgeven voor technieker"} onClick={()=>unSign(currentWorkorder)}><h4>Handtekening verwijderen</h4></div>
                                                        )}
                                                </div>
                                        </div>
                                </div>

                        </div>
                )
        }
        else {
                return <Loading />
        }
        // }
        // } else {
        // return (
        // <NoWerkbon />
        // )
        // }

}
export default Details;