import React, { createContext, useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useGlobal, addReducer } from 'reactn';



export const MyWerkbonContext = createContext();

export const MyWerkbonProvider = props => {
  const [searchVars, setSearchVars] = useGlobal('searchVars');
  const [me, setMe] = useGlobal('me');
  const [workOrders, setWorkorders] = useGlobal('myWorkorders');
  const query = gql`
  query MyWorkorders($includeAll: Boolean){
    myWorkorders(includeAll: $includeAll) {
    id
    workorder_number
    date
    customer
    owner
    status
    description
    contact
    signature
    updated_by
    updated_at
    created_by
    created_at
    name
    }
}
      `;

      useEffect(() => {
        if (!!me) {
          if (me.role.code === 'ADMIN') {
            // setSearchVars(old => {
            //   return {...old, includeAll: true}
            // })
            console.log('include all in myWOs')
            setSearchVars({...searchVars, includeAll: true})
          }
          else {
            // setSearchVars(old => {
            //   return {...old, includeAll: false}
            // })
            setSearchVars({...searchVars, includeAll: false})
          }
        }
      }, [me]);


      const handleData = res => {
        console.log('res in provider: ', res);
        setWorkorders([...res.myWorkorders]);
      }
      const { loading, error, data, refetch } = useQuery(query, {fetchPolicy: 'network-only', 
      variables: searchVars,
      onCompleted: res => {
       return handleData(res);
      }});

      addReducer('refetchMyWorkorders', async () => {
        const res = await refetch();
        if (!!res.data) {
          handleData(res.data);
        }
      });

      //const [werkbon, setWerkbon] = useState(data)
      if (loading || !workOrders) return (
        <MyWerkbonContext.Provider value={false}>
                {props.children}
          </MyWerkbonContext.Provider>
      )
      if (error) return (
        <MyWerkbonContext.Provider value={!error}>
        {props.children}
      </MyWerkbonContext.Provider>
      )

      return (
          <MyWerkbonContext.Provider value={{data: workOrders, refetch: () => refetch()}}>
                {props.children}
          </MyWerkbonContext.Provider>
      )
}
