export const refreshTokenSetup = (res) => {
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000; 

const refreshToken = async () => {
    try{
   const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    console.log('newAuthRes:', newAuthRes);
    console.log('new auth Token', newAuthRes.id_token); 
    setTimeout(refreshToken, refreshTiming); 
    }catch(e){
        console.log('Kan refresh token niet refreshen');
    }
}; 

setTimeout(refreshToken, refreshTiming); 
};